.table-pagination {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  .table-pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .table-row-per-page {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .table-row-pera {
      }
      .tabel-row-select {
      }
    }
    .table-data-no {
    }
    .table-change-page-button {
    }
  }
}
