.care-customer {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  padding: 20px;
  .care-customer-heading {
    margin-bottom: 40px;
  }
  .care-customer-form {
    padding: 0px 50px;
    .care-customer-form-column {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 10px;
      

      .care-customer-form-button-back {
        width: 60%;
        border-color: red;
        color: red;
        font-size: 17px;
        text-decoration: none;
        height: 50px;
        margin-top: 70px;
      }
      .care-customer-form-button-next {
        width: 60% !important;
        background-color: red;
        color: #fff;
        font-size: 17px;
        text-decoration: none;
        height: 50px;
        margin-top: 70px;
      }
    }
  }
}
