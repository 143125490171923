@import "../../style/GlobalStyle.scss";

.dashboard__menu-item {
  display: grid;
  margin: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  width: 100%;
  // justify-content: center;
  align-items: center;
  padding: 8px 5px;
  grid-template-columns: 100px 1fr;
  // border-top-right-radius: 30px;
  // border-bottom-right-radius: 30px;

  .dashbord-menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dashbord-menu-icon-text {
    // display: flex;
    // align-items: start;
    // justify-content: start;
    .icon-text {
      // width: 100px;
      margin-right: auto;
    }
  }
}

.dashboard__menu-item:hover {
  background-color: $color-black;
  color: #00d1ff !important;
}
