@import "../../style/mixins.scss";
@import "../../style/GlobalStyle.scss";

.header {
  width: 100%;
  padding: 2px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-gray-2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu-button {
      z-index: 300;
      display: none;
    }
    .menu-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      gap: 30px;
      .admin-avatar-menu {
        min-width: 260px;
        max-width: 320px;
        background-color: $color-gray-2;
        padding: 10px;
        position: absolute;
        bottom: -160px;
        border-radius: 10px;
        left: -175px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(21, 21, 21, 0.579) 0px 2px 8px 0px;
        .admin-avatar-menu-ul {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          flex-direction: column;
          .admin-avatar-menu-li {
            text-decoration: none;
            list-style: none;
            width: 100%;
            .admin-avatar-menu-button {
              // width: 100%;
              color: black;
              border-color: black;
              border: none;
              .css-1d6wzja-MuiButton-startIcon > *:nth-of-type(1) {
                font-size: 26px;
              }
            }
          }
        }
      }
      .user-details {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        .user-avtar-container {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .user-avtar {
            height: 40px;
            width: 40px;
          }
        }
        .user-name {
          font-weight: 600;
        }
      }
      .exit-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
