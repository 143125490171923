// COLOR VARIABLES

$color-black: #000;
$color-black-2: #1b2e35;
$color-white: #fff;

$color-black-transprent-1: #00000094;
$color-black-transprent-2: #00000094;
$color-gray-dark: rgb(36, 35, 35);
$color-gray-light: rgb(185, 185, 185);
$color-red: red;
$color-beige-1: #e1d0bf;
$color-beige-2: #d2b99f;
$color-beige-3: #d9af82;
$color-beige-4: #655340;
$color-beige-5: #322920;
$color-beige-6: #1c1712;
$color-blue-1: rgb(86, 158, 240);
$color-blue-2: #007dfe;
$color-blue-3: #4dbecb;
$color-blue-4: #ccedff;
$color-blue-5: #00d1ff;
$color-blue-6: #111c1d;
$color-gray-1: #f2f2f2;
$color-gray-2: #fafafa;
$color-gray-3: #bab6bc;
$color-grey-4: #27222b;
$color-grey-5: #1d191f;
$color-grey-6: #151316;
$color-text-heading: #f7f7f7;
$color-text-body: #f7f7f7;
$color-text-safe-green: #1db05f;
$color-text-attention-yellow: #ffb800;
$color-text-danger-red: #d7042a;
$color-special-transparent: rgba(0, 0, 0, 0.466);
$color-special-black: #000000;
$color-special-white: #ffffff;
$color-green-1: #d0ffcc;
$color-green-2: #2dca73;
$color-green-3: #1db05f;
$color-green-4: #178d4c;
$color-yellow-1: #fff7cc;
$color-yellow-2: #ffb800;
$color-yellow-3: #eaa800;
$color-yellow-4: #cc9300;
$color-red-1: #ffcccc;
$color-red-2: #ff0b37;
$color-red-3: #d7042a;
$color-red-4: #cc092c;
$color-player-1: #109d73;
$color-player-2: #a83e94;
$color-player-3: #d0703b;
$color-player-4: #80a0b2;
$color-purple-3: #6b3885;
$heading-color: #f2e1ce;
$text-color: #e7e7e7;
$placeholder-color: #aaaaaa;
// COLOR VARIABLES

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
