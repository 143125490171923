.payment-succes-page {
  width: 1600px!important;
  margin-top: 100px;
  background-color: #d5d1d1;
  padding: 15px 40px;
  /* width: 1185px; */
  /* font-size: 12px; */
}
.invoice-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  padding: 0px 20px;
}
.invoice-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 12px 20px;
}
.invoice-company-details {
}
.invoice-header-company-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}
.invoice-heading {
  width: 100%;
  background: rgb(73, 40, 123);
  color: #fff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
.invoice-subheading {
  width: 100%;
  background: rgb(73, 40, 123);
  color: #fff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
.invoice-certificate-vehicle-container {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.invoice-certificate {
  width: 100%;
}
.invoice-vehicle {
  width: 100%;
}
.invoice-lable-input {
  display: grid;
  width: 100%;
  justify-content: space-between;
  border: 1px solid gray;
  align-items: center;
  padding-left: 40px;
  grid-template-columns: 1fr 1fr;
  border-top: none;
}
.invoice-persnol-details {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.invoice-plan-features-w {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px 0px;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
}
.invoice-plan-features-g {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px 0px;
  text-align: center;
  background-color: rgb(232, 221, 221);
  border-radius: 5px;
}
.invoice-subheading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 10px 0px;
}
.invoice-subheading-containt {
  display: grid;
  /* justify-content: center; */
  align-items: center;
  gap: 20px;
  margin: 10px 0px;
  grid-template-columns: 1fr 1fr;
}
