@import "../../globleColor.scss";
.nav {
  height: 100px;
  width: 100%;
  background-color: rgb(255, 250, 249);
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0px;
  padding: 0px 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  @include md {
    background-color: white;
    padding: 0%;
    height: 70px;
  }

  .nav-header {
    @include md {
      padding: 10px 15px;
    }
    padding: 10px 30px;
    .nav-title {
      height: 100px;
      cursor: pointer;
      @include md {
        height: 60px;
      }
    }
  }
  .nav-links {
    display: inline;
    float: right;
    font-size: 18px;

    @include lg {
    }
    @include md {
      display: none;
    }
    a {
      display: inline-block;
      padding: 13px 10px 13px 10px;
      text-decoration: none;
      color: black;
      font-weight: 600;
      border-bottom: 2px solid black;
    }
    a:hover {
      color: red !important;
      border-bottom: 2px solid red;
    }
    .navbar-login-button {
      margin: 0px 10px;
      background: rgb(190, 110, 12);
    }
  }
  .menuBtn {
    display: none;
    @include lg {
    }
    @include md {
      display: block;
      padding-right: 20px;
    }
  }
  .nav_menu {
    background-color: white;
    position: fixed;
    width: 100vw;
    height: 100vh;
    transition: 2s;
    z-index: -10;
    .menuNav-links {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      height: 90%;
      width: 100%;
      a {
        text-decoration: none;
        font-size: 27px;
        color: black;
        font-weight: 600;
      }
      a:hover {
        color: red !important;
        border-bottom: 2px solid red;
      }
      .navbar-login-button {
        margin: 0px 10px;
        background: rgb(190, 110, 12);
        width: 50%;
      }
    }
  }
}
