.text-field {
  height: 100px;
  width: 100%;
  .input-label {
    width: 100%;
    padding-left: 3px;
    font-weight: 500;
    margin-bottom: 4px;
  }
}
