@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,200;1,700&family=Neuton:wght@200;300;700&family=Poppins:ital,wght@0,100;0,200;0,400;0,500;1,100;1,200;1,600&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
h1 {
  font-weight: 600;
}
h2 {
  font-weight: 600;
}
