.create-technician-model {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000098;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .create-technician-model-container {
    width: 700px;
    background: #fff;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
    flex-direction: column;
    .create-technician-model-textField-lable {
      width: 100%;
      text-align: start;
      margin-left: 10px;
    }
    .create-technician-model-textField {
      width: 100%;
    }
    .create-technician-model-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: 30px;
      .create-technician-model-saveBtn {
        width: 100%;
        background: #000;
        color: #fff;
        height: 43px !important;
      }
      .create-technician-model-closeBtn {
        width: 100%;
        background: #fff;
        color: #000;
        border-color: #000;
        height: 43px !important;
      }
    }
  }
}
