.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  width: 100%;
  margin-top: 100px;
  // width: 100vw;
  .container {
    padding: 20px;
    height: 100%;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .logo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 50px;
      .logo-svg {
      }
      .logo-text {
        font-size: 35px;
        font-weight: 700;
      }
    }
    .email-textField {
      width: 100%;
    }
    .password-textField {
      width: 100%;
    }
    .forget-password {
      width: 100%;
      text-align: right;
      font-size: 16px;
      margin-top: -12px;
      margin-bottom: 20px;
      cursor: pointer;
      position: relative;
      .link {
        text-decoration: underline;
        font-weight: 400;
      }
    }
    .login-button {
      width: 100%;
    }
    .signup-link {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-size: 17px;
      margin-top: 20px;
      cursor: pointer;
      .text {
      }
      .link {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
}
