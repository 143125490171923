.subscriptions-plans {
  margin-top: 100px;
  width: 100%;
  .subscriptions-plans-container {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-direction: column;
    .subscriptions-plans-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .subscriptions-plans-header-button {
        color: #000;
        border-color: #000;
      }
      .subscriptions-plans-toggle-button-container {
        // border-bottom: 1px solid #000;
        padding: 0px 20px;
        display: flex;
        gap: 10px;
        .subscriptions-plans-toggle-button {
          color: #000;
          border-color: #000;
        }
      }
    }
    .subscriptions-plans-search-bar {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin: 20px;
      align-items: center;
      .subscriptions-plans-search-button {
        background: #000;
        width: 150px;
        height: 55px;
        font-size: 16px;
      }
      .subscriptions-plans-search-area-container {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        border: 2px solid #b5b0b0;
        border-radius: 7px;
        padding: 0px 10px;
        .subscriptions-plans-search-search {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          .subscriptions-plans-search-input {
            fieldset {
              border: none !important;
            }
          }
          .search-icons {
            // position: absolute;
            width: 40px;
            height: 40px;
            // left: 15px;
            // top: 5px;
          }
        }
      }
    }
    .subscriptions-plans-table {
      width: 100%;
      position: relative;
    }
  }
}
