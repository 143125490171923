@import "../../globleColor.scss";

.footer {
  width: 100%;
  .footer-container {
    width: 100%;
    .footer-media-container {
      background-color: gray;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 20px;
      color: #fff;
      font-size: 22px;
      @include sm {
        flex-direction: column;
        gap: 20px;
      }
      .footer-media {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .footer-media-one {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #fff;
          border: 2px solid #b2d3e5;
          transition: 1.2s;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
          .footer-media-one-icon {
            color: #000;
          }
        }
        .footer-media-one:hover {
          scale: 1.3;
          background-color: #000;
          .footer-media-one-icon {
            color: #fff;
          }
        }
      }
      .footer-contact-us {
        @include sm {
          text-align: center;
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          gap: 10px;
        }
      }
    }
    .footer-copy-right-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #292727;
      padding: 20px;
      color: #fff;
      .footer-copy-right-header {
        @include sm {
          text-align: center;
          line-height: 26px;
        }
      }
    }
  }
}
