@import "../.././globleColor.scss";
.circlular-loader {
  width: 100vw;
  height: 100vh;
  background-color: #00000066;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader-curcle {
    color: blue;
    height: 70px !important;
    width: 70px !important;
  }
}
