.loc-get{
    width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        font-family: "Poppins", sans-serif;
        /* font-family: ''; */
}
.card {
    width: 90%;
    height: 300px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    display: none;
  }
  .card-load {
    width: 90%;
    height: 300px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    text-align: center;
  }

  .card img {
    width: 110px;
  }
  .card-load img {
    width: 110px;
  }

  .card p {
    text-align: center;
  }

  .card button {
    color: #fff;
    background-color: blue;
    outline: none;
    border: none;
    padding: 5px 35px;
    font-size: 18px;
    border-radius: 5px;
  }
  .perm-note {
    display: none;
  }
  .notfound-note {
    display: none;
  }