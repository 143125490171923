.clclcl {
  height: 100vh!important;
  overflow: hidden;
  .payment-success-page-2 {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    //   margin-top: 200px;
    background-color: rgba(221, 211, 240, 0.622);

    .payment-success-img-container {
      width: 600px;
      height: 400px;

      .payment-success-img {
        height: 100%;
        width: 100%;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }

      .payment-success-button-container {
        width: 100%;
        text-align: center;
        margin-top: 30px;
        display: flex;
        gap: 30px;
        text-align: center;
        align-items: center;
        justify-content: center;

        .payment-success-button {
          background: #ffb824;
          color: #fff;
          width: 200px;
        }
      }
    }
  }
}