.admin-customer-full-details {
  width: 100vw;
  height: 100vh;
  background-color: #00000061;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  .admin-customer-full-details-container {
    width: 900px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    border-radius: 10px;
    position: relative;
    gap: 10px;
    .admin-customer-full-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }
    .admin-customer-full-details-button {
      color: #000;
      border-color: #000;
    }
    .admin-customer-full-details-details {
      width: 100%;
    }
  }
}
