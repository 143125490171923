@import "../../style/GlobalStyle.scss";
@import "../../style/mixins.scss";

.dashbord {
  width: 100%;
  .dashbord-container {
    width: 100%;
    // display: grid;
    // justify-content: center;
    // grid-template-columns: 20% 80%;
    .dashbord-menu-container {
      height: 100vh;
      background-color: $color-gray-1;
      display: flex;
      align-items: center;
      padding: 20px 0px;
      flex-direction: column;
      gap: 40px;
      position: fixed;
      width: 20%;
      z-index: 5;
      .dashbord-logo {
      }
      .dashbord-menu-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .dashbord-menu-logoutButton {
        position: absolute;
        bottom: 10px;
      }
    }
    .dashbord-active-index {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20%;
      width: 80%;

      .dashbord-active-index-header {
        position: fixed;
        top: 0%;
        width: 80%;
        z-index: 4;
      }
      .dashbord-active-index-container {
        width: 95%;
      }
    }
  }
}
